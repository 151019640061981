import React from 'react'
import './App.css'

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<p>Content</p>
			</header>
		</div>
	)
}

export default App
